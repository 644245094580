<template>
  <b-dropdown offset="10"
              right
              class="dropdown-menu-left hide_caret default_style_dropdown"
              ref="Campaigns_dropdown_select"
              style="min-width: 197px !important;"
              :no-caret="true">
    <div  @click="fetchCampaignOnDropdown"
      class="dropdown_header border !border-[#757A8A] font-semibold !h-[2.375rem] px-2.5 py-2 !rounded-[8px] d-flex align-items-center "
      slot="button-content">
       <span class="text-[14px] text-[#757A8A] font-semibold"
         v-if="getAnalyticsFilters.campaign.name">{{ limitTextLength(getAnalyticsFilters.campaign.name, 20) }}</span>
      <span class="text-[14px] text-[#757A8A] font-semibold" v-else>Campaign</span>

      <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
    </div>
    <ul style="max-height: 20rem; overflow-x: hidden; overflow-y: auto;" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')"  id="campaign-dropdown-scroll"
        class="!min-w-[270px] text-[#202324]  py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
      <div
        class="mx-[1rem] h-[2.375rem] mb-[1rem] mt-[0.8rem] border !border-[#757A8A] rounded-lg justify-between px-2 items-center flex">
        <input class="w-full h-auto !border-none" type="text"
               :value="getCampaigns.search" @input="debounceCampaignDropdownSearch" placeholder="Search by keyword...">
        <i
          class="fal fa-search"></i>
      </div>
      <hr>
      <template class="">
        <template v-if="getCampaignLoaders.retrieve">
          <clip-loader :size="size" :color="color"></clip-loader>
        </template>
        <template v-if="getComponentCampaignList && getComponentCampaignList.length">
          <li
            class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between items-center font-poppins"
            v-for="campaign in getComponentCampaignList"
          >
            <Checkbox
              :id="campaign._id"
              :label="campaign.name"
              @click="selectItem(campaign)"
              labelClass="text-[#3C4549]"
              customClass="w-full justify-between flex-row-reverse"
            ></Checkbox>
          </li>
        </template>
        <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length" class="font-poppins text-center text-[12px] my-3">Your brand does not have any campaigns.</li>
        <li v-else-if="!getCampaignLoaders.retrieve && !getCampaignsList.length" class="font-poppins text-center text-[12px] my-3">{{ searchFiltersMessages('campaigns').empty_list_dropdown }}</li>
      </template>
    </ul>
  </b-dropdown>
</template>
<script>
import { mapGetters } from 'vuex'
export default ({
  components: {
      Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue'),
  },
  data () {
    return {
      search_campaign: ''
    }
  },
  computed: {
    ...mapGetters([
      'getAnalyticsFilters',
      'getComponentCampaignList',
      'getCampaigns',
      'getCampaignLoaders',
      'getCampaignsList',
      'getWorkspace'
    ])
  },
  methods: {
    selectItem (cta) {
      this.getAnalyticsFilters.campaign = cta
      this.$refs.dropdown.hide()
      this.getAnalyticsFilters.link.shorten_url = null
      this.getAnalyticsFilters.link._id = null
      this.$parent.countTotalLinks(this.getWorkspace._id, cta._id)
    }
  }
})
</script>
<style scopped lang="less">
.radio_input {
  padding: 7px 15px;

  .avatar {
    .brand_img {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
  }
  i {
    margin-top: 7px;
  }
  .checked {
    color: #2dd678;
  }
}
</style>
